@import url('https://fonts.cdnfonts.com/css/longsile');

.navBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
}

.navLogo {
    width: 240px;
    height: auto;
}

.links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.links a {
    margin-left: 15px;
    font-family: longsile, 'Times New Roman', Times, serif;
    font-size: 20px;

}