.welcomeSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
}

.welcomeTextSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

}

.welcomeTitle {
    font-family: longsile, 'Times New Roman', Times, serif;
    font-size: 45px;
}

.welcomePhoto {
    width: 700px;
    height: auto;
    border-radius: 8px;
}